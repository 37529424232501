import { template as template_4b624b000bf24053a3e2b1eb5b674402 } from "@ember/template-compiler";
const FKText = template_4b624b000bf24053a3e2b1eb5b674402(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
