import { template as template_10314b115f9a4814892eecb09457fb78 } from "@ember/template-compiler";
const FKControlMenuContainer = template_10314b115f9a4814892eecb09457fb78(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
